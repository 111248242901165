

import part1 from "./stock-images/finlit/image version/part1.jpg";
import part2 from "./stock-images/finlit/image version/part2.png";
import part3 from "./stock-images/finlit/image version/part3.png";
import part4 from "./stock-images/finlit/image version/part4.png";
import part5 from "./stock-images/finlit/image version/part5.png";
import part6 from "./stock-images/finlit/image version/part6.png";
import part7 from "./stock-images/finlit/image version/part7.png";
import part8 from "./stock-images/finlit/image version/part8.png";
import part9 from "./stock-images/finlit/image version/part9.png";
import part10 from "./stock-images/finlit/image version/part10.png";


//finlit-images


export default [part1, part4, part6, part7, part8, part10];

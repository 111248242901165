

import part1 from "./stock-images/calendifi/image version/Notification Lock part 1.png";
import part2 from "./stock-images/calendifi/image version/Log In part 2.png";
import part3 from "./stock-images/calendifi/image version/Upload part 3.png";
import part4 from "./stock-images/calendifi/image version/Activities part 4.png";
import part5 from "./stock-images/calendifi/image version/Calendar part 5.png";
import part6 from "./stock-images/calendifi/image version/Settings part 6.png";


//calendifi-images


export default [part1, part2, part3, part4, part5, part6];
